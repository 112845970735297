<template>
  <mental-background-layout>
    <div class="th-content">
      <h3 class="th-title">Thank you</h3>
      <p class="th-p">
        Thank you. One of our consultants will call you for a FREE consultation
        appointment regarding your personalized plan. Please check your email
        inbox for your FREE part 1 of your personalized plan.
      </p>
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_1934_457)" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M56.666 28.333l-25 23.334-8.333-8.334"/><path d="M40 78.333c21.171 0 38.334-17.162 38.334-38.333C78.334 18.83 61.17 1.667 40 1.667 18.83 1.667 1.667 18.829 1.667 40S18.829 78.333 40 78.333z"/></g><defs><clipPath id="clip0_1934_457"><path fill="#fff" d="M0 0h80v80H0z"/></clipPath></defs></svg>
    </div>
  </mental-background-layout>
</template>

<script>
import MentalBackgroundLayout from '../layouts/MentalBackgroundLayout.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ThankYou',
  components: { MentalBackgroundLayout },
  computed: {
    ...mapGetters('quiz', ['isCustomQuiz']),
    ...mapState('quiz', ['quizData']),
  },
  mounted() {
    setTimeout(() => {
      if (this.isCustomQuiz) {
        this.$router.push({
          name: 'LeadDynamicResult',
          params: {
            slug: this.$route.query.slug,
          },
          query: {
            statistic_id: this.$route.query.statistic_id,
            ...this.$route.query,
          },
        })
      } else {
        this.$router.push({
          name: 'LeadQuizResult',
          query: {
            statistic_id: this.$route.query.statistic_id,
            ...this.$route.query,
          },
        })
      }
    }, 1000)
  },
}
</script>

<style lang="scss" scoped>
.th {
  &-content {
    max-width: 327px;
    margin: 104px auto 0;
    display: grid;
    justify-items: center;
  }
  &-title {
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: var(--color-white);
  }
  &-p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--color-white);
    margin: 8px 0 38px;
  }
}
</style>
