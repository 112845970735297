<template>
  <div class="page-wrapper">
    <div class="mb-logo">
      <router-link to="/">
        <h2 class="mb-title">Mental Future</h2>
      </router-link>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MentalBackgroundLayout',
}
</script>

<style lang="scss">
.mb-title {
  font-family: var(--run);
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  color: var(--color-white);
}

.mb-logo {
  display: flex;
  justify-content: flex-start;
  margin-top: 36px;
  margin-left: 36px;
}
</style>
